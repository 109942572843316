<template>
  <div id="download-area">
    <breadcrumb class="mt-2" :item="$route.matched" :current="$route.path" />
    <h5 class="mt-4" style="margin: 0">Additional Documents</h5>
    <hr />

    <div class="table-header-action">
      <div class="table-header-action__left">
        <button
          v-if="userData.role === 'ADMIN'"
          class="btn btn-pompe-primary btn-sm"
          @click.prevent="openUploadModal('create')"
        >
          &nbsp; Upload File
          <b-icon class="ml-2 mr-2" icon="upload" aria-hidden="true"></b-icon>
        </button>
      </div>
      <div class="table-header-action__right">
        <b-input-group>
          <b-input-group-prepend>
            <pop-over-button
              v-bind="config.popover"
              class="mr-2"
              btn-variant="secondary"
              @toggle-popover="config.popover.show = !config.popover.show"
            >
              <template #popover-content>
                <b-form-checkbox-group
                  v-model="selectedCategories"
                  :options="config.modal.options.type"
                  value-field="value"
                  text-field="text"
                  class="mt-1"
                  @input="
                    fetchData({
                      criteriaByType: {
                        field: 'type',
                        op: 'in',
                        value: `[${selectedCategories.toString()}]`,
                      },
                    })
                  "
                />
              </template>
            </pop-over-button>
          </b-input-group-prepend>
          <b-form-input
            v-model="search"
            size="sm"
            name="search"
            placeholder="Search"
          ></b-form-input>
          <b-input-group-append>
            <button
              class="btn btn-sm btn-pompe-secondary"
              @click="
                fetchData({
                  criteriaByName: {
                    field: 'name',
                    op: 'is',
                    value: search,
                  },
                })
              "
            >
              <b-icon class="mr-1" icon="search" aria-hidden="true"></b-icon>
            </button>
            <b-button
              v-if="search || selectedCategories.length"
              size="sm"
              variant="danger"
              @click="resetSearch"
            >
              <b-icon
                class="mr-1"
                icon="x"
                scale="2"
                aria-hidden="true"
              ></b-icon>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </div>
    </div>

    <pompe-table v-bind="config" @fetch-data="fetchData">
      <template #action="{ row }">
        <a
          v-if="userData.role === 'ADMIN'"
          href="javascript:void(0)"
          class="anchor-link ml-2"
          @click="openUploadModal('edit', row)"
        >
          <b-icon icon="pencil" aria-hidden="true"></b-icon>
        </a>
        <a
          href="javascript:void(0)"
          class="anchor-link ml-2"
          @click="downloadFile(row.fileId, row.name)"
        >
          <b-icon icon="download" aria-hidden="true"></b-icon>
        </a>
        <a
          href="javascript:void(0)"
          class="anchor-link ml-2"
          @click="deleteConfirm(row.id)"
        >
          <b-icon icon="trash" aria-hidden="true"></b-icon>
        </a>
      </template>
    </pompe-table>

    <modal-upload-file
      :config="config.modal"
      :loadingUpload="config.loadingUpload"
      :loadingSave="config.loadingSave"
      :errors="errors"
      @action:upload-file="uploadFile"
      @action:submit-upload="postFileData"
      @action:close="closeUploadModal"
    />
  </div>
</template>

<script>
import axios from 'axios';
import ModalUploadFile from "./SubComponents/ModalUploadFile.vue";

import download from "downloadjs";
import Swal from "sweetalert2";

export default {
  components: {
    ModalUploadFile,
  },
  props: {
    userData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    const _this = this;
    return {
      config: {
        popover: {
          title: "Select Categories",
          btnText: "Filter by Categories",
          btnVariant: "outline-info",
          show: false,
        },
        modal: {
          title: "Upload File",
          modalSize: "md",
          show: false,
          state: null,
          form: {
            fileId: null,
            name: null,
            desc: null,
            type: "EXAMINATION_PROTOCOL",
          },
          options: {
            type: [
              {
                text: "Examination Protocol",
                value: "EXAMINATION_PROTOCOL",
              },
              {
                text: "Guidelines",
                value: "GUIDELINES",
              },
              {
                text: "Quality of Life Questionnaires",
                value: "QUALITY_OF_LIFE_QUESTIONNAIRES",
              },
              {
                text: "Patient Information / informed consent",
                value: "PATIENT_INFORMATION",
              },
              {
                text: "Kostengutsprachegesuch",
                value: "KOSTENGUT_SPRACHE_GESUCH",
              },
            ],
          },
        },
        data: [],
        columns: [
          {
            key: "type",
            label: "Category",
            format: (val) => _this.formatCategory(val),
          },
          {
            key: "desc",
            label: "Description",
          },
          {
            key: "name",
            label: "Filename",
          },
          {
            key: "creationDate",
            label: "Date Created",
            format: (val) => _this.formatDateWithTime(val),
          },
          {
            key: "action",
            label: "Action",
          },
        ],
        paging: {
          size: 5,
          page: 0,
        },
        loadingUpload: false,
        loadingSave: false,
        loadingDownload: false,
      },
      search: null,
      selectedCategories: [],
      errors: [],
    };
  },
  mounted() {
    this.fetchData(null);
  },
  methods: {
    formatCategory(category) {
      switch (category) {
        case "GUIDELINES":
          return "Guidelines";
        case "EXAMINATION_PROTOCOL":
          return "Examination Protocol";
        case "QUALITY_OF_LIFE_QUESTIONNAIRES":
          return "Quality of Life Questionnaires";
        case "PATIENT_INFORMATION":
          return "Patient Information / informed consent";
        case "KOSTENGUT_SPRACHE_GESUCH":
          return "Kostengutsprachegesuch";
      }
    },
    async downloadFile(id, name) {
      this.config.loadingDownload = true;
      this.$vToastify.success({
        title: "File requested",
        body: `We are processing your file, download will start shortly 
          <br/> <br/> <small> You can close this message </small>`,
      });
      try {
        const fileUrl = `${this.$apiEndpoints.download}/${id}/content`;
        const { data, headers } = await axios.get(fileUrl, {
          headers: {
            'Authorization': this.$cookies.get('token')
          },
          responseType: 'arraybuffer',
          dataType: 'blob',
        });

        const fileType = headers['content-type'];
        await download(data, name, fileType)
      } catch (error) {
        console.error(error);
      } finally {
        this.config.loadingDownload = false;
      }
    },
    openUploadModal(state, row) {
      this.config.modal.show = true;
      this.config.modal.state = state;

      if (state === "edit") {
        this.populateForm(row);
      }

      this.errors = [];   
    },
    closeUploadModal() {
      this.config.modal.show = false;
      this.config.modal.state = null;
      this.config.modal.form = {
        fileId: null,
        name: null,
        desc: null,
        type: "EXAMINATION_PROTOCOL",
      };
    },
    populateForm(row) {
      this.config.modal.form = { ...row };
    },
    fetchData(customPayload) {
      let search = null;
      let pagination = null;

      if (
        customPayload &&
        (customPayload.criteriaByName || customPayload.criteriaByType)
      ) {
        let tempSearch = [];

        if (customPayload.criteriaByName)
          tempSearch.push(customPayload.criteriaByName);
        if (customPayload.criteriaByType)
          tempSearch.push(customPayload.criteriaByType);

        search = {
          criteria: {
            field: null,
            op: "and",
            value: null,
            of: [...tempSearch],
          },
        };

        if (!this.selectedCategories.length) {
          search = {
            criteria: {
              field: "name",
              op: "is",
              value: this.search,
            },
          };
        }

        if (!this.search) {
          search = {};
        }
      }

      pagination = {
        paging: {
          page:
          customPayload && customPayload.pagination
            ? customPayload.pagination.page
            : this.config.paging.page,
          size:
            customPayload && customPayload.pagination
              ? customPayload.pagination.size
              : this.config.paging.size,
        }
      };

      let payload = {
        ...pagination,
      };

      if (search) {
        payload = {
          ...payload,
          ...search,
        };
      }

      this.$requestService.post(
        this.$apiEndpoints.staticData + "/search",
        payload,
        this.handleFetchDataSuccess,
        this.handleFetchDataFailed
      );
    },
    handleFetchDataSuccess(response) {
      this.config.data = [...response.data.content];
      this.config.pagination = response.data.pageable;
      this.config.pagination["currentPage"] = response.data.number;
      this.config.pagination["totalRows"] = response.data.totalElements;
      this.config.pagination["totalPage"] = response.data.totalPages;
    },
    handleFetchDataFailed(e) {
      console.log(e);
    },
    uploadFile(file) {
      this.config.loadingUpload = true;

      let formData = new FormData();
      formData.append("file_content", file);
      formData.append("media_type", file.type);

      this.$requestService.postFile(
        this.$apiEndpoints.download,
        formData,
        this.handleUploadSuccess,
        this.handleUploadFailed
      );
    },
    handleUploadSuccess(response) {
      this.getFileId(response.headers.location);
    },
    handleUploadFailed(e) {
      console.log(e);
      this.config.loadingUpload = false;
    },
    getFileId(url) {
      this.$requestService.get(
        url,
        {},
        this.handleGetFileIdSuccess,
        this.handleGetFileIdFailed
      ).then(() => {
        this.config.loadingUpload = false;
      });
    },
    handleGetFileIdSuccess(response) {
      this.config.modal.form.fileId = response.data.id;
    },
    handleGetFileIdFailed(e) {
      console.log(e);
    },
    postFileData() {
      this.config.loadingSave = true;

      let method = "post";
      let url = this.$apiEndpoints.staticData;

      if (this.config.modal.state === "edit") {
        method = "put";
        url = `${url}/${this.config.modal.form.id}?force=false`;
      }

      this.$requestService[method](
        url,
        this.config.modal.form,
        this.postStaticDataSuccess,
        this.postStaticDataFailed
      ).then(() => {
        this.config.loadingSave = false;
      });
    },
    postStaticDataSuccess() {
      this.fetchData();
      this.closeUploadModal();
    },
    postStaticDataFailed(e) {
      if (e.response.status === 422) {
        this.errors  = [...e.response.data.errors]
      }
    },
    deleteConfirm(id) {
      Swal.fire({
        title: "Confirm",
        text: "Are you sure you want to delete this row ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Delete",
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      this.$requestService.delete(
        this.$apiEndpoints.staticData + `/${id}`,
        {},
        this.deleteDataSuccess,
        this.deleteDataFailed
      );
    },
    deleteDataSuccess() {
      this.fetchData();
    },
    resetSearch() {
      this.search = null;
      this.selectedCategories = [];

      this.fetchData(null);
    },
  },
};
</script>

<style lang="scss">
#download-area {
  height: 60vh !important;
}
.table-header-action {
  display: flex;
  justify-content: space-between;
}
.btn {
  border-radius: 0 !important;
  padding: 0.3em 0.3em 0.3em 0.5em !important;
}
thead.th-label th {
  font-size: 14px !important;
  font-weight: 500;
}
tbody.tr-label {
  font-size: 14px;
}
</style>
