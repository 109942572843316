var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"download-area"}},[_c('breadcrumb',{staticClass:"mt-2",attrs:{"item":_vm.$route.matched,"current":_vm.$route.path}}),_c('h5',{staticClass:"mt-4",staticStyle:{"margin":"0"}},[_vm._v("Additional Documents")]),_c('hr'),_c('div',{staticClass:"table-header-action"},[_c('div',{staticClass:"table-header-action__left"},[(_vm.userData.role === 'ADMIN')?_c('button',{staticClass:"btn btn-pompe-primary btn-sm",on:{"click":function($event){$event.preventDefault();return _vm.openUploadModal('create')}}},[_vm._v("   Upload File "),_c('b-icon',{staticClass:"ml-2 mr-2",attrs:{"icon":"upload","aria-hidden":"true"}})],1):_vm._e()]),_c('div',{staticClass:"table-header-action__right"},[_c('b-input-group',[_c('b-input-group-prepend',[_c('pop-over-button',_vm._b({staticClass:"mr-2",attrs:{"btn-variant":"secondary"},on:{"toggle-popover":function($event){_vm.config.popover.show = !_vm.config.popover.show}},scopedSlots:_vm._u([{key:"popover-content",fn:function(){return [_c('b-form-checkbox-group',{staticClass:"mt-1",attrs:{"options":_vm.config.modal.options.type,"value-field":"value","text-field":"text"},on:{"input":function($event){_vm.fetchData({
                    criteriaByType: {
                      field: 'type',
                      op: 'in',
                      value: ("[" + (_vm.selectedCategories.toString()) + "]"),
                    },
                  })}},model:{value:(_vm.selectedCategories),callback:function ($$v) {_vm.selectedCategories=$$v},expression:"selectedCategories"}})]},proxy:true}])},'pop-over-button',_vm.config.popover,false))],1),_c('b-form-input',{attrs:{"size":"sm","name":"search","placeholder":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-input-group-append',[_c('button',{staticClass:"btn btn-sm btn-pompe-secondary",on:{"click":function($event){return _vm.fetchData({
                criteriaByName: {
                  field: 'name',
                  op: 'is',
                  value: _vm.search,
                },
              })}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"search","aria-hidden":"true"}})],1),(_vm.search || _vm.selectedCategories.length)?_c('b-button',{attrs:{"size":"sm","variant":"danger"},on:{"click":_vm.resetSearch}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"x","scale":"2","aria-hidden":"true"}})],1):_vm._e()],1)],1)],1)]),_c('pompe-table',_vm._b({on:{"fetch-data":_vm.fetchData},scopedSlots:_vm._u([{key:"action",fn:function(ref){
              var row = ref.row;
return [(_vm.userData.role === 'ADMIN')?_c('a',{staticClass:"anchor-link ml-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.openUploadModal('edit', row)}}},[_c('b-icon',{attrs:{"icon":"pencil","aria-hidden":"true"}})],1):_vm._e(),_c('a',{staticClass:"anchor-link ml-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.downloadFile(row.fileId, row.name)}}},[_c('b-icon',{attrs:{"icon":"download","aria-hidden":"true"}})],1),_c('a',{staticClass:"anchor-link ml-2",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.deleteConfirm(row.id)}}},[_c('b-icon',{attrs:{"icon":"trash","aria-hidden":"true"}})],1)]}}])},'pompe-table',_vm.config,false)),_c('modal-upload-file',{attrs:{"config":_vm.config.modal,"loadingUpload":_vm.config.loadingUpload,"loadingSave":_vm.config.loadingSave,"errors":_vm.errors},on:{"action:upload-file":_vm.uploadFile,"action:submit-upload":_vm.postFileData,"action:close":_vm.closeUploadModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }